body {
  padding: 0;
  margin: 0;
}

.caas-s-wrapper {

  color: #333;
  width: 970px;
  margin: 0 auto;

  @media screen and (max-width: 992px) {
    width: 738px;
    padding: 0 15px
  }
  @media screen and (max-width: 767px) {
    width: auto;
    min-width: 290px
  }

  * {
    font-family: 'Roboto', sans-serif;
    font-style: normal; 
    font-weight: 400;
    font-size: 12px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .caas-s-main-content {
    position: relative;
  }
}



.hyperlink-style-inherit{
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  font-size:inherit;
}

.label-font-size{
  font-size:1.25rem;
}  

.error-section{
  height:25px;
  width:25px;
}

.error-content-section{
  margin-top:10px; 
  margin-left:10px;   
}

.error-icon{
  max-height:100%;
  max-width:100%;
}

